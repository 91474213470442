@import-normalize;
@import 'the-new-css-reset/css/reset.css';
@import './variables.scss';
@import './functions.scss';

///
/// GLOBAL STYLES
///

html {
	font-size: 62.5%;
	height: 100%;

	&.dark {
		transition: $transition-duration-theme;
	}

	&.light {
		filter: invert(90%);
		transition: $transition-duration-theme;
	}

	body {
		background: $bg-color-primary;
		color: $text-color-primary;
		font-family: $font-default;
		font-size: #{fluid(18px, 20px)};
		font-weight: $font-weight-normal;
		height: 100%;
		line-height: 1.5;

		#root {
			display: flex;
			flex-direction: column;
			min-height: 100%;
			padding: 0 1.6rem;

			a {
				color: $text-color-primary;
			}

			.container {
				flex: 1;
				margin-inline: auto;
				width: min(1200px, 100% - 4.8rem);

				h2 ~ p {
					margin-bottom: 3.2rem;
				}

				p + p {
					margin-top: 2rem;
				}
			}
		}
	}
}

/// UTILITIES

.sr-only {
	border-width: 0;
	clip: rect(0, 0, 0, 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	white-space: nowrap;
	width: 1px;
}
