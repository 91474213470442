@import '../../styles/mixins.scss';

.background {
	inset: 0px;
	opacity: 1;
	position: fixed;
	@supports (backdrop-filter: blur(1.6rem)) {
		backdrop-filter: blur(1.6rem);
		background: hsl(0 0% 100% / 0.1);
	}
	z-index: 200;
}

.modal {
	inset: 1rem;
	margin-inline: auto;
	max-height: 80%;
	max-width: min(1200px, 100% - 4.8rem);
	padding: 1rem;
	position: fixed;
	z-index: 201;

	@include md-min {
		padding: 3rem;
	}

	.action {
		height: 4rem;
		text-align: right;
	}

	.body {
		height: 100%;
		text-align: center;

		img {
			object-fit: contain;
		}
	}
}
