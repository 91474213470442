@import '../../styles/functions.scss';

.footer {
	display: flex;
	justify-content: center;
	margin-inline: auto;
	padding-block: 3.2rem;
	text-align: center;
	width: min(1200px, 100% - 4.8rem);

	a {
		font-size: #{fluid(24px, 30px)};
		padding-inline: 3.2rem;
		transition: all $transition-duration-primary ease-in-out;

		&:hover {
			transform: scale(1.25);
		}
	}
}
