@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.table {
	border-collapse: collapse;
	margin-bottom: 3.2rem;
	width: 100%;

	a {
		display: inline-table;
		overflow: hidden;
		position: relative;

		span {
			left: 0;
			overflow: hidden;
			position: absolute;
			top: 0;
			transform: translateX(-100%);
			transition: transform $transition-duration-primary ease;

			&::before {
				color: $link-color-primary;
				content: attr(data-content);
				display: inline-table;
				opacity: 0.5;
				text-decoration: underline;
				transform: translateX(100%);
				transition: transform $transition-duration-primary ease;
			}
		}

		&:hover span {
			transform: translateX(0);

			&::before {
				transform: translateX(0);
			}
		}
	}

	td {
		@include sm-max {
			display: grid;
			padding: 0.8rem;

			&:first-child {
				padding-top: 3.2rem;
			}

			&:last-child {
				padding-bottom: 3.2rem;
			}

			&::before {
				content: attr(data-cell) ': ';
				font-weight: $font-weight-bold;
			}
		}
	}

	td,
	th {
		padding: 0.5rem;
		text-align: left;

		@include md-min {
			padding: 2.4rem;

			&:first-child {
				padding-left: 0;
			}

			&:last-child {
				padding-right: 0;
			}
		}
	}

	th {
		font-weight: $font-weight-bold;

		@include sm-max {
			display: none;
		}
	}

	tr {
		border-bottom: 1px solid $border-color-primary;

		@include sm-max {
			border-top: 1px solid $border-color-primary;
		}
	}
}

:global(.light) .table span::before {
	color: $link-color-light-theme;
	filter: invert(85%);
	opacity: 1;
}
