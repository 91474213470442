///
/// SASS VARIABLES
///

/// COLORS
$chinese-black: #111;
$old-lace: #fff6e5;
$rifle-green: #4d4637;
$tropical-rain-forest: #007665;

/// FONTS & TEXT
$font-default: 'Barlow Semi Condensed', sans-serif;
$font-weight-normal: 300;
$font-weight-bold: 500;
$link-color-primary: $rifle-green;
$link-color-light-theme: $tropical-rain-forest;
$text-color-primary: $old-lace;

/// BACKGROUND
$bg-color-primary: $chinese-black;

/// RESPONSIVE BREAKPOINTS
/// Chosen based on content for this project, not standard device dimensions
$screen-sm-min: 650px;
$screen-md-min: 850px;
$screen-lg-min: 1200px;
$screen-xs-max: ($screen-sm-min - 1);
$screen-sm-max: ($screen-md-min - 1);
$screen-md-max: ($screen-lg-min - 1);

/// MEDIA QUERIES
$mq-xs-max: 'only screen and (max-width:#{$screen-xs-max})';
$mq-sm-max: 'only screen and (max-width:#{$screen-sm-max})';
$mq-sm-min: 'only screen and (min-width:#{$screen-sm-min})';
$mq-md-min: 'only screen and (min-width:#{$screen-md-min})';

/// ANIMATIONS
$transition-duration-primary: 300ms;
$transition-duration-theme: 3000ms;

/// BORDERS
$border-color-primary: $old-lace;
