@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.themeToggle {
	input {
		opacity: 0;
		position: absolute;

		&:not(:checked) + label span {
			transform: translateX(4.8rem);

			@include sm-min {
				transform: translateX(2.4rem);
			}
		}
	}

	label {
		align-items: center;
		background-color: $text-color-primary;
		border-radius: 5rem;
		color: $bg-color-primary;
		cursor: pointer;
		display: flex;
		font-size: 3.6rem;
		height: 5.2rem;
		justify-content: space-between;
		padding: 1rem;
		position: relative;
		width: 10rem;

		@include sm-min {
			font-size: 1.8rem;
			height: 2.6rem;
			padding: 0.5rem;
			width: 5rem;
		}
	}

	span {
		background-color: $bg-color-primary;
		border-radius: 50%;
		height: 4.4rem;
		left: 0.4rem;
		position: absolute;
		top: 0.4rem;
		transition: transform 0.2s linear;
		width: 4.4rem;

		@include sm-min {
			height: 2.2rem;
			left: 0.2rem;
			top: 0.2rem;
			width: 2.2rem;
		}
	}
}
