@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.header {
	align-items: center;
	background-color: $bg-color-primary;
	display: flex;
	justify-content: space-between;
	margin-inline: auto;
	position: sticky;
	top: 0;
	width: min(1200px, 100% - 4.8rem);
	z-index: 100;

	.logo {
		font-family: 'Teko', sans-serif;
		font-size: 4.8rem;
		margin-block: 1rem;

		a {
			border-bottom: none;
			text-decoration: none;
		}
	}

	button {
		display: none;

		@include xs-max {
			background-color: transparent;
			border: 0;
			color: $text-color-primary;
			display: block;
			font-size: 4.8rem;
			position: absolute;
			right: 0;
			top: 1.4rem;
			width: 4.8rem;
			z-index: 9999;
		}
	}

	nav ul {
		display: flex;
		gap: 4.8rem;
		list-style: none;
		margin: 0;
		padding-block: 3.2rem;

		@include xs-max {
			background: hsl(0 0% 0% / 0.9);
			flex-direction: column;
			gap: clamp(2.4rem, 5vw, 4.8rem);
			inset: 0 0 0 30%;
			padding: min(30vh, 16rem) 2em;
			position: fixed;
			transform: translateX(100%);
			transition: transform 500ms ease-out;
			z-index: 1000;

			@supports (backdrop-filter: blur(1.6rem)) {
				backdrop-filter: blur(1.6rem);
				background: hsl(0 0% 100% / 0.1);
			}

			&[data-mobile-nav-visible='true'] {
				transform: translateX(0);
			}
		}

		li {
			@include xs-max {
				font-size: 3rem;
				padding-block: 1.5rem;
			}

			a {
				position: relative;
				text-decoration: none;
				transition: color $transition-duration-primary ease-in-out;

				&:global.active {
					cursor: default;
					font-weight: $font-weight-bold;
				}

				&:not(:global(.active)) {
					&::before {
						background-color: $text-color-primary;
						content: '';
						height: 1px;
						margin-top: 3px;
						position: absolute;
						top: 100%;
						transform: scaleX(0);
						transition: transform $transition-duration-primary
							ease-in-out;
						width: 100%;
					}

					&:hover::before {
						transform: scaleX(1);
					}
				}
			}
		}
	}
}
