@import '../../styles/functions.scss';

.h1 {
	font-size: #{fluid(32px, 40px)};
	text-wrap: balance;
}

.h2 {
	font-size: #{fluid(24px, 32px)};
}
